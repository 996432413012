import React from 'react';
import Image from 'gatsby-image';
import { FaAngleDoubleDown } from 'react-icons/fa';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Location from '../components/Location';
import { ProjectsGrid } from '../components/projects-grid';
import { ProjectsCarousel } from '../components/projects-carousel';
import { useGraphQL } from '../hooks';

const ProjectsPage = () => {
  const { projectsHero, allSanityProject } = useGraphQL();

  return (
    <Layout>
      <SEO title="Projects" />
      <div className="relative">
        <Image
          style={{ height: `50vh`, minHeight: `22rem` }}
          fluid={projectsHero.childImageSharp.fluid}
          alt="Aerial shot looking down at houses in various stages of construction"
          loading="eager"
        />
        <div className="absolute inset-0 flex items-end justify-center max-w-3xl p-4 mx-auto">
          <AnchorLink
            className="flex items-center justify-center px-2 py-1 leading-none bg-white"
            href="#projects"
            offset="56"
          >
            <FaAngleDoubleDown className="text-green-500" />
          </AnchorLink>
        </div>
      </div>

      <article
        id="projects"
        className="flex flex-col p-4 my-8 md:mb-16 sm:px-12 lg:px-24"
      >
        <div className="w-full max-w-5xl mx-auto">
          <h1 className="w-full mb-4 text-4xl font-bold leading-none text-green-500 uppercase">
            Projects
          </h1>
          <div>
            <div className="pl-4 text-gray-600 border-l-4 border-green-500 lg:text-xl">
              <h2 className="text-2xl font-bold">Time is Money</h2>
              <p className="mb-4 ">
                At Morr Homes and Projects, we understand time is money. That’s
                why we manage your entire project within our office. From
                site-inspection to sign off. Our team of designers, supervisors
                and trades are experts in their field. Skilled to meet every
                standard of a quality design and build project. Reducing your
                stress levels and giving you peace of mind. We share your goal
                in building to budget and delivering on time.
              </p>
            </div>
          </div>
          <ProjectsCarousel projects={allSanityProject.nodes} />
          <ProjectsGrid projects={allSanityProject.nodes} />
        </div>
      </article>

      <Location />
    </Layout>
  );
};

export default ProjectsPage;
