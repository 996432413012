import React, { useState } from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaPlus } from 'react-icons/fa';
import Image from 'gatsby-image';
import Carousel from 'nuka-carousel';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { useGraphQL } from '../hooks';

function ProjectsCarousel() {
  const { allSanityProject } = useGraphQL();

  const isBrowser = typeof window !== 'undefined';
  let filteredResult = allSanityProject.nodes.filter(() => true);

  if (isBrowser) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const filterService = urlParams.get('filter');

    if (filterService) {
      filteredResult = allSanityProject.nodes.filter((project) => {
        return project.services.find(
          (service) => service.title.toLowerCase() === filterService
        );
      });
    }
  }

  const [currentSlide, setCurrentSlide] = useState(0);

  const back = () => setCurrentSlide((prev) => prev - 1);
  const next = () => setCurrentSlide((prev) => prev + 1);

  return (
    <article className="py-12">
      <div className="relative border-4 border-green-500 md:px-4 md:flex md:items-center">
        <ArrowLeft
          onClick={back}
          isDisabled={currentSlide === 0}
          className="hidden text-green-500 focus:outline-none focus:shadow-outline md:block"
        />

        <div className="flex-1 md:py-12">
          <Carousel
            slideIndex={currentSlide}
            afterSlide={(index) => setCurrentSlide(index)}
            autoplay
            autoplayInterval={5000}
            cellAlign="center"
            dragging
            enableKeyboardControls
            renderBottomCenterControls={null}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
            swiping
            wrapAround
          >
            {filteredResult.map((project) => (
              <div key={project._id} className="keen-slider__slide">
                <div className="grid grid-cols-2 gap-4 md:px-4">
                  <Link
                    aria-label={project.title}
                    tabIndex={-1}
                    to={`/projects/${project.slug.current}/`}
                    className="relative col-span-2 transition duration-150 ease-in-out md:col-span-1"
                  >
                    <div className="relative h-64 group">
                      <div className="absolute inset-0 flex bg-black">
                        <Image
                          className="flex-1 opacity-75"
                          fluid={project.mainImage.asset.fluid}
                          alt={project.title}
                        />
                      </div>
                      {project.awardBadge ? (
                        <div className="absolute bottom-0 right-0 z-10 w-24 h-24 mb-2 mr-3 rounded-full">
                          <Image
                            fluid={project.awardBadge.asset.fluid}
                            alt="HIA Award Winner."
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <FaPlus className="absolute top-0 right-0 mt-4 mr-4 text-xl text-white group-hover:text-green-500" />
                    </div>
                  </Link>
                  <div className="col-span-2 px-4 md:col-span-1 md:px-0">
                    <h2 className="mb-2 text-2xl font-bold leading-tight text-green-500 uppercase">
                      <Link to={`/projects/${project.slug.current}/`}>
                        {project.title}
                      </Link>
                    </h2>
                    <p className="mt-8">
                      <strong>Type:</strong>{' '}
                      <span className="inline-block">
                        {project.projectType.title}
                      </span>
                    </p>
                    <p>
                      <strong>Value:</strong>{' '}
                      <span className="inline-block">{project.value}</span>
                    </p>
                    <p>
                      <strong>Location:</strong>{' '}
                      <span className="inline-block">{project.location}</span>
                    </p>
                    {project.title !== 'Antilles' &&
                    project.title !== 'Tobago' ? (
                      <p>
                        <strong>Designed by:</strong>{' '}
                        <span className="inline-block">
                          {project.designer.name}
                        </span>
                      </p>
                    ) : null}
                    <p>
                      <strong>Completion Date:</strong>{' '}
                      <span className="inline-block">
                        {project.ongoing ? 'Ongoing' : project.completionDate}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <ArrowRight
          onClick={next}
          isDisabled={currentSlide === allSanityProject.nodes.length - 1}
          className="hidden text-green-500 focus:outline-none focus:shadow-outline md:block"
        />

        <div className="flex justify-end px-4 py-1 space-x-4 text-white bg-green-500 md:hidden">
          <ArrowLeft onClick={back} isDisabled={currentSlide === 0} />
          <ArrowRight
            onClick={next}
            isDisabled={currentSlide === allSanityProject.nodes.length - 1}
          />
        </div>
      </div>
      <style>{`.slider-frame { padding: 0 !important }`}</style>
    </article>
  );
}

function ArrowLeft({ isDisabled, onClick, className }) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={className}
    >
      <FaAngleDoubleLeft className="w-6 h-auto" />
    </button>
  );
}

ArrowLeft.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

function ArrowRight({ isDisabled, onClick, className }) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={className}
    >
      <FaAngleDoubleRight className="w-6 h-auto" />
    </button>
  );
}

ArrowRight.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export { ProjectsCarousel };
