import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FaPlus } from 'react-icons/fa';
import lodashChunk from 'lodash/chunk';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

function ProjectsGrid({ projects }) {
  const isBrowser = typeof window !== 'undefined';

  const CHUNK_LENGTH = 5;

  let projectChunk = lodashChunk(projects, CHUNK_LENGTH);

  if (isBrowser) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const filterService = urlParams.get('filter');

    const filteredResult = projects.filter((project) => {
      return project.services.find(
        (service) => service.title.toLowerCase() === filterService
      );
    });

    if (filterService) projectChunk = lodashChunk(filteredResult, CHUNK_LENGTH);
  }

  return (
    <article className="grid gap-2 py-12">
      {projectChunk.map((chunk) => {
        const threeCol = chunk.slice(0, 3);
        const twoCol = chunk.slice(3);
        return (
          <div key={nanoid()} className="grid gap-2">
            <div className="grid gap-2 md:grid-cols-3">
              {threeCol.map((col) => (
                <Project key={nanoid()} group={col} />
              ))}
            </div>
            <div className="grid gap-2 md:grid-cols-2">
              {twoCol.map((col) => (
                <Project key={nanoid()} group={col} />
              ))}
            </div>
          </div>
        );
      })}
    </article>
  );
}

ProjectsGrid.propTypes = {
  projects: PropTypes.array.isRequired,
};
function Project({ group }) {
  return (
    <Link
      key={group._id}
      to={`/projects/${group.slug.current}/`}
      className="relative w-full h-64 transition duration-150 ease-in-out bg-gray-300 group"
    >
      {group.mainImage ? (
        <Image
          className="object-cover w-full h-64 opacity-75 group-hover:opacity-100"
          fluid={group.mainImage.asset.fluid}
          alt={group.title}
        />
      ) : (
        ''
      )}

      {group.awardBadge ? (
        <div className="absolute bottom-0 right-0 z-10 w-24 h-24 mb-2 mr-3 rounded-full">
          <Image fluid={group.awardBadge.asset.fluid} alt="HIA Award Winner." />
        </div>
      ) : (
        ''
      )}
      <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full p-8 transition duration-150 ease-in-out bg-white bg-opacity-75 opacity-0 hover:opacity-100">
        <p className="text-xl font-extrabold leading-none tracking-wide text-center text-green-500 uppercase">
          {group.title}
        </p>
      </div>
      <FaPlus
        aria-hidden
        focusable={false}
        className="absolute top-0 right-0 mt-4 mr-4 text-xl text-white pointer-events-none group-hover:text-green-500"
      />
    </Link>
  );
}

Project.propTypes = {
  group: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    awardBadge: PropTypes.object,
    mainImage: PropTypes.object.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
  }),
};

export { ProjectsGrid };
